import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Section from "../components/section"
import Items from "../components/items"
import ItemBox from "../components/itembox"

export default () => <>
    <SEO title="Notes" pathname="/notes/"/>
    <Layout>
        <Section sectionTitle="Notes">
            <Items>
                <ItemBox 
                    title="Supersymmetry."
                    links={<><a href="/notes/susy.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/user/dbs26/">David Skinner</a> in 2019</>}
                />
                <ItemBox 
                    title="Applications of Differential Geometry to Physics."
                    links={<><a href="/notes/adgp.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/user/md327/">Maciej Dunajski</a> in 2016</>}
                />
                <ItemBox 
                    title="Black Holes."
                    links={<><a href="/notes/blackholes.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/user/hsr1000/">Harvey Reall</a> in 2016</>}
                />
                <ItemBox 
                    title="Symmetries, Fields and Particles."
                    links={<><a href="/notes/sfp.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/people/n.dorey">Nick Dorey</a> in 2015</>}
                />
                <ItemBox 
                    title="Quantum Field Theory."
                    links={<><a href="/notes/qft.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/people/m.j.perry/">Malcolm Perry</a> in 2015</>}
                />
                <ItemBox 
                    title="Cosmology."
                    links={<><a href="/notes/cosmology.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/people/j.r.fergusson/">James Fergusson</a> and <a href="http://www.damtp.cam.ac.uk/people/cmm92/">David Marsh</a> in 2015</>}
                />
                <ItemBox 
                    title="General Relativity."
                    links={<><a href="/notes/gr.pdf">PDF</a></>}
                    blurb={<>Based on a course given by <a href="http://www.damtp.cam.ac.uk/people/us248/">Ulrich Sperhake</a> in 2015</>}
                />
            </Items>
        </Section>
    </Layout>
</>
