import React from "react"

import styles from "./items.module.css"

export default ({ children }) => (
    <div className={styles.items}>
        {children}
        <div className={styles.itemPlaceholder}></div>
        <div className={styles.itemPlaceholder}></div>
        <div className={styles.itemPlaceholder}></div>
    </div>
)
