import React from "react"

import styles from "./itembox.module.css"

export default ({ title, links, blurb }) => (
    <div className={styles.item}>
        <p className={styles.itemTitle}><b>{title}</b> [{links}]</p>
        <p className={styles.itemBlurb}>{blurb}</p>
    </div>
)
